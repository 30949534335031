
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import DriverSet from '@/components/DriverSet.vue'
import PageUtil from "@/utils/page_util";
import {IFeedbackItem} from "@/models/logistics_model";
import {dateFormat} from "@/utils/common";

interface IState {
  list: IFeedbackItem[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
  item?: IFeedbackItem
  reason: string
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '用户',
    dataIndex: 'user',
    key: 'nickname',
  },
  {
    title: '联系方式',
    dataIndex: 'user',
    key: 'mobile',
  },
  {
    title: '反馈内容',
    dataIndex: 'content',
    key: 'content',
  },
  {
    title: '反馈时间',
    dataIndex: 'create_date',
    key: 'create_date',
  },
  {
    title: '操作',
    key: 'action',
    width: 250
  },
];

export default defineComponent({
  name: 'FeedbackList',
  components: {DriverSet},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      visible: false,
      item: undefined,
      reason: '', //备注
      searches: {
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize,
        state: '',// 0没回复1回复
        keywords: ''
      },
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogistics.getFeedbackList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        const data:any = res.data
        state.total = data.count
        state.list = data.lists
      }
    }

    // 设置modal显示隐藏
    const setVisible = (flg: boolean, item: IFeedbackItem | undefined = undefined) => {
      state.visible = flg
      state.reason = ''
      if(flg){
        state.item = item
      }else{
        setTimeout(() => state.item = undefined, 300)
      }
    }

    // 回复
    const onReply = async (flg: boolean) => {
      state.loading = true
      const res = await ApiLogistics.replyFeedback({
        "pid": state.item?.id,
        "reply": state.reason
      })
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('回复成功')
        await getList()
      } else {
        message.error(res?.msg || '审核失败');
      }
      setVisible(false)
    }

    // 去详情
    const goDetail = (item: IFeedbackItem) => {
      router.push({path: '/feedback/detail', query: {id: item.id}}).then()
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      onReply,
      setVisible,
      dateFormat,
      goDetail,
    }
  }
})
